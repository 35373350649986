import Vue from "vue";
import { config, library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Regular
import { faStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faSuitcase } from "@fortawesome/pro-regular-svg-icons/faSuitcase";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons/faMapMarkerAlt";
import { faCamera } from "@fortawesome/pro-regular-svg-icons/faCamera";
import { faLongArrowRight } from "@fortawesome/pro-regular-svg-icons/faLongArrowRight";
import { faThumbsUp } from "@fortawesome/pro-regular-svg-icons/faThumbsUp";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faTimes as farTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

// Light
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faShare } from "@fortawesome/pro-light-svg-icons/faShare";
import { faHeart } from "@fortawesome/pro-light-svg-icons/faHeart";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus";
import { faEnvelope as falEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faBars as falBars } from "@fortawesome/pro-light-svg-icons/faBars";

// Solid
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh";
import { faShare as fasShare } from "@fortawesome/free-solid-svg-icons/faShare";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStarHalf as fasStarHalf } from "@fortawesome/free-solid-svg-icons/faStarHalf";

// Brand
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faPinterest } from "@fortawesome/free-brands-svg-icons/faPinterest";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons/faFacebookMessenger";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";

// Font Awesome
library.add(
  // FAR
  faBars,
  faStar,
  faSuitcase,
  faCopy,
  faInfoCircle,
  faMapMarkerAlt,
  faCamera,
  faThumbsUp,
  faLongArrowRight,
  faCheck,
  faBars,
  faEnvelope,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  farTimes,
  faExclamationTriangle,
  faCheckCircle,
  faBuilding,
  // FAL
  faPlus,
  faPhone,
  faShare,
  faUser,
  faHeart,
  faTimes,
  faMinus,
  falEnvelope,
  falBars,
  // FAS
  faHome,
  faSearch,
  faTh,
  fasShare,
  fasHeart,
  faPhoneAlt,
  fasStar,
  fasStarHalf,
  // FAB
  faFacebook,
  faInstagram,
  faYoutube,
  faTiktok,
  faPinterest,
  faWhatsapp,
  faFacebookMessenger,
  faTwitter
);

config.autoAddCss = false;

Vue.component("font-awesome-icon", FontAwesomeIcon);
