<template>
  <div class="holidayguru">
    <div id="holidayguru-trustpilot-buttons-widget-container">
      <HgTrustpilotButtons :lang="lang" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: "de"
    }
  }
}
</script>
