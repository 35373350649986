<template>
  <div></div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },
  created() {
    if (!window || process.env.NODE_ENV === "development" || this.id == null) {
      return
    }

    this.addGtmScript()
  },
  methods: {
    addGtmScript() {
      let e = document.createElement("script")
      e.src = `//www.googletagmanager.com/gtm.js?id=${this.id}`
      e.type = "text/javascript"
      e.async = true
      document.getElementsByTagName("head")[0].appendChild(e)
    }
  }
}
</script>
