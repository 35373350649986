<template>
  <div class="holidayguru">
    <nav class="navigation">
      <a :href="logoUrl">
        <GuruLogo :lang="locale" class="guru-logo" />
      </a>

      <div class="support">
        <span class="tw-text-sm tw-text-right">
          {{ telephoneMessage }}
        </span>
        <a
          class="tw-font-bold tw-text-base tw-text-teal-600 tw-text-right"
          :href="`tel:${telephoneNumber}`"
        >
          {{ telephoneLabel }}
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    logoLink: {
      type: String,
      default: null
    },
    telephoneNumber: {
      type: String,
      default: "+49230194580685"
    },
    telephoneLabel: {
      type: String,
      default: "+49 2301-94580685"
    },
    telephoneMessage: {
      type: String,
      default: "Wir sind täglich von 8-22 Uhr für dich da!"
    },
    locale: {
      type: String,
      default: "de"
    }
  },
  computed: {
    logoUrl() {
      if (this.logoLink != null) {
        return this.logoLink
      }

      if (this.locale == "at") {
        return "https://www.urlaubsguru.at/"
      }

      if (this.locale == "ch") {
        return "https://www.holidayguru.ch/"
      }

      if (this.locale == "es") {
        return "https://www.holidayguru.es/"
      }

      if (this.locale == "nl") {
        return "https://www.holidayguru.nl/"
      }

      return "https://www.urlaubsguru.de/"
    }
  }
}
</script>

<style>
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  gap: 0.5rem;
  border-bottom: 1px solid #e5e5e5;
}

.guru-logo {
  height: 57px !important;
  width: fit-content !important;
}

.support {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: Asap, sans-serif;
}

@media (max-width: 492px) {
  .navigation {
    padding: 0.325rem;
  }
}

@media (max-width: 562px) {
  .support a {
    font-size: 0.875rem;
  }
}
</style>
