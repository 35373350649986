<template>
  <div class="holidayguru">
    <div
      :class="
        `hg-navbar-container ${navBorder ? 'hg-navbar-container__border' : ''}`
      "
    >
      <HgNavbar
        class="hg-navbar"
        :style="navbarStyle"
        :lang="lang"
        :isMounted="mounted"
        :showBookmarkButton="false"
        :showUserButton="false"
      />
    </div>
  </div>
</template>

<script>
import { HgNavbar, HgNavbarLocales } from "@uniqgmbh/holidayguru-components"

export default {
  components: {
    HgNavbar
  },
  props: {
    locale: {
      type: String,
      default: null
    },
    navBorder: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: String,
      default: ""
    },
    maxWidth: {
      type: String,
      default: ""
    },
    supportTitle: {
      type: String,
      default: ""
    },
    supportSubline: {
      type: String,
      default: ""
    },
    supportPhone: {
      type: String,
      default: ""
    },
    supportPhoneLabel: {
      type: String,
      default: ""
    },
    supportPage: {
      type: String,
      default: ""
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      navbarStyle: "",
      lang: "de",
      mounted: false
    }
  },
  created() {
    if (this.fontSize !== "") {
      this.navbarStyle += "font-size:" + this.fontSize + "; "
    }

    if (this.maxWidth !== "") {
      this.navbarStyle += "max-width:" + this.maxWidth + "; "
    }

    if (this.locale) {
      this.lang = this.locale
    } else if (window.location.host.includes(".at")) {
      this.lang = "at"
    } else if (window.location.host.includes(".ch")) {
      this.lang = "ch"
    } else if (window.location.host.includes(".es")) {
      this.lang = "es"
    } else if (window.location.host.includes(".nl")) {
      this.lang = "nl"
    } else {
      this.lang = "de"
    }

    // Update the locale strings
    if (this.lang == "de") {
      HgNavbarLocales.de.logo.url = "https://www.urlaubsguru.de/"
      HgNavbarLocales.de.items[0].url = "https://www.urlaubsguru.de/angebote/"
      HgNavbarLocales.de.items[0].linkType = "a"

      if (this.supportTitle) {
        HgNavbarLocales.de.supportPhone.title = this.supportTitle
      }

      if (this.supportSubline) {
        HgNavbarLocales.de.supportPhone.subline = this.supportSubline
      }

      if (this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.de.supportPhone.phone = this.supportPhone
        HgNavbarLocales.de.supportPhone.phoneLabel = this.supportPhoneLabel
      }

      if (this.supportPage) {
        HgNavbarLocales.de.showSupportPage = true
        HgNavbarLocales.de.supportPageUrl = this.supportPage
      }
    } else if (this.lang == "at") {
      HgNavbarLocales.at.logo.url = "https://www.urlaubsguru.at/"
      HgNavbarLocales.at.items[0].url = "https://www.urlaubsguru.at/angebote/"
      HgNavbarLocales.at.items[0].linkType = "a"

      if (this.supportTitle) {
        HgNavbarLocales.at.supportPhone.title = this.supportTitle
      }

      if (this.supportSubline) {
        HgNavbarLocales.at.supportPhone.subline = this.supportSubline
      }

      if (this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.at.supportPhone.phone = this.supportPhone
        HgNavbarLocales.at.supportPhone.phoneLabel = this.supportPhoneLabel
      }

      if (this.supportPage) {
        HgNavbarLocales.at.showSupportPage = true
        HgNavbarLocales.at.supportPageUrl = this.supportPage
      }
    } else if (this.lang == "ch") {
      HgNavbarLocales.ch.logo.url = "https://www.holidayguru.ch/"
      HgNavbarLocales.ch.items[0].url = "https://www.holidayguru.ch/angebote/"
      HgNavbarLocales.ch.items[0].linkType = "a"

      if (this.supportTitle) {
        HgNavbarLocales.ch.supportPhone.title = this.supportTitle
      }

      if (this.supportSubline) {
        HgNavbarLocales.ch.supportPhone.subline = this.supportSubline
      }

      if (this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.ch.supportPhone.phone = this.supportPhone
        HgNavbarLocales.ch.supportPhone.phoneLabel = this.supportPhoneLabel
      }

      if (this.supportPage) {
        HgNavbarLocales.ch.showSupportPage = true
        HgNavbarLocales.ch.supportPageUrl = this.supportPage
      }
    } else if (this.lang == "nl") {
      HgNavbarLocales.nl.logo.url = "https://www.holidayguru.nl/"
      HgNavbarLocales.nl.items[0].url = "https://www.holidayguru.nl/aanbiedingen/"
      HgNavbarLocales.nl.items[0].linkType = "a"

      if (this.supportTitle && this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.nl.showSupportPhone = true
        HgNavbarLocales.nl.supportPhone = {
          title: this.supportTitle,
          subline: this.supportSubline,
          phone: this.supportPhone,
          phoneLabel: this.supportPhoneLabel
        }
      }

      if (this.supportPage) {
        HgNavbarLocales.nl.showSupportPage = true
        HgNavbarLocales.nl.supportPageUrl = this.supportPage
      }
    } else if (this.lang == "es") {
      HgNavbarLocales.es.logo.url = "https://www.holidayguru.es/"
      HgNavbarLocales.es.items[0].url = "https://www.holidayguru.es/deals/"
      HgNavbarLocales.es.items[0].linkType = "a"

      if (this.supportTitle && this.supportPhone && this.supportPhoneLabel) {
        HgNavbarLocales.es.showSupportPhone = true
        HgNavbarLocales.es.supportPhone = {
          title: this.supportTitle,
          subline: this.supportSubline,
          phone: this.supportPhone,
          phoneLabel: this.supportPhoneLabel
        }
      }

      if (this.supportPage) {
        HgNavbarLocales.es.showSupportPage = true
        HgNavbarLocales.es.supportPageUrl = this.supportPage
      }
    }
  },
  mounted() {
    if (this.delay > 0) {
      setTimeout(() => {
        this.mounted = true
      }, this.delay)
    } else {
      this.mounted = true
    }
  }
}
</script>

<style>
.hg-navbar-header {
  width: 180px;
}
@media screen and (min-width: 768px) {
  .hg-navbar-header {
    width: 240px;
  }
}

.hg-navbar-container {
  width: 100%;
  background-color: white;
  min-height: 54px;
}

@media (min-width: 1024px) {
  .hg-navbar-container {
    min-height: 61px;
  }
}

.hg-navbar {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  z-index: 50;
}

.hg-navbar-container__border {
  border-bottom: 4px solid #dcfaf5;
}

.hg-deals-heading-nav a {
  width: 100%;
}

.hg-header__nav-mobile-container {
  display: none;
}

.hg-header__nav-mobile-container--active {
  display: block;
}
</style>
