import Vue from "vue";
import VueI18n from "vue-i18n";

import messages from "../../lang/config";

let locale = "de";

if (window.location.host.includes(".at")) {
  locale = "at";
} else if (window.location.host.includes(".ch")) {
  locale = "ch";
} else if (window.location.host.includes(".es")) {
  locale = "es";
} else if (window.location.host.includes(".nl")) {
  locale = "nl";
}

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: locale,
  messages: messages
});

export const currentLocale = locale;
