<template>
  <div></div>
</template>

<script>
/* eslint-disable no-console */
import CookieConsentMixin from "@/mixins/CookieConsentMixin";

export default {
  mixins: [CookieConsentMixin],
  data() {
    return {
      adUrl:
        "https://cdn.stroeerdigitalgroup.de/metatag/live/SDG_ConsentOnly/metaTag.min.js"
    };
  },
  created() {
    if (!window) {
      console.log("!window");
      return;
    }
    if (process.env.NODE_ENV === "development") {
      console.log("node_env = dev");
      // return;
    }

    this.loadCMP(this.adUrl, this.cmpCallback);
  },
  methods: {
    cmpCallback() {
      console.log("🚀 Loaded Stroeer");
      this.cookieStore.stroeerLoaded = true;

      window.addEventListener("metaTagSystemCmpConsentAvailable", () => {
        console.log("=== SDG Event - Consent Available");
        this.consentCheck();
      });
      window.addEventListener("metaTagSystemCmpChoiceSelected", () => {
        console.log("=== SDG Event - CMP Choice Selected");
        this.consentCheck();
      });
    },
    loadCMP(url, callback) {
      var e = document.createElement("script");
      e.src = url;
      e.type = "text/javascript";
      e.addEventListener("load", callback);
      document.getElementsByTagName("head")[0].appendChild(e);
      console.log("✅  added Stroeer");
    }
  }
};
</script>
