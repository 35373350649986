import deValidateMessages from "vee-validate/dist/locale/de.json";
import esValidateMessages from "vee-validate/dist/locale/es.json";
import nlValidateMessages from "vee-validate/dist/locale/nl.json";

import de from "./de";
import ch from "./ch";
import at from "./at";
import nl from "./nl";
import es from "./es";

const messages = {
  de: {
    ...de,
    "zip-code-input": "PLZ",
    validation: { ...deValidateMessages.messages }
  },
  ch: {
    ...ch,
    "zip-code-input": "PLZ",
    validation: deValidateMessages.messages
  },
  at: {
    ...at,
    "zip-code-input": "PLZ",
    validation: deValidateMessages.messages
  },
  es: {
    ...es,
    "zip-code-input": "Código postal",
    validation: esValidateMessages.messages
  },
  nl: {
    ...nl,
    "zip-code-input": "Postcode",
    validation: nlValidateMessages.messages
  }
};

export default messages;
